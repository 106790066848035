import styled from 'styled-components';
import { Link } from 'gatsby';
import { spacing, fontSizes, colors, media } from '../../styles/theme';

export const Wrapper = styled(Link)`
  display: flex;
  text-decoration: none;
  flex-direction: row;
  align-items: center;
  padding-top: ${spacing.half};
  padding-bottom: ${spacing.half};
  width: 100%;
  margin-left: ${spacing.half};
  margin-right: ${spacing.half};
  margin-top: 0;
  background-color: transparent;
  border-radius: 6px;
  ${media.desktop`
    flex-direction: column;
    width: 300px;
    background-color: ${colors.white};
    align-items: flex-start;
    margin-top: ${spacing.eighteen};
    padding: 0;
  `}
  border-top: 1px solid ${colors.borderGrey};
  &:first-child {
    border-top: 0;
  }
`;

export const Content = styled.div`
  padding: ${spacing.base};
`;

export const Image = styled.img`
  width: 150px;
  height: 80px;
  object-fit: cover;
  border-radius: 6px 6px 0 0;
  ${media.desktop`
    width: 300px;
    height: 225px;
  `}
`;

export const Title = styled.h4`
  font-size: ${fontSizes.normal};
  font-family: 'BressayBold';
  line-height: 1.2;
  margin-bottom: ${spacing.base};
  letter-spacing: -0.015em;
`;

export const Body = styled.p`
  font-size: ${fontSizes.small};
  margin-top: 0;
  margin-bottom: 0;
`;
