import React from 'react';
import { Wrapper, Text } from './styles';
import { convertDateToMonthDay } from '../../services/date';

export type BlogAuthorProps = {
  author: string;
  published: Date;
};

const BlogAuthor = ({ author, published }: BlogAuthorProps) => {
  return (
    <Wrapper>
      <Text>
        <b>By {author}</b> | {convertDateToMonthDay(published)}
      </Text>
    </Wrapper>
  );
};

export default BlogAuthor;
