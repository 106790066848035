import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { Wrapper, Title, Content } from './styles';
import BlogArticle from '../../atoms/blogArticle';

export type RelatedArticlesProps = {
  uid: string;
};

export type AllBlog = {
  allPrismicBlog: {
    edges: [
      {
        node: {
          uid: string;
          first_publication_date: Date;
          tags: string[];
          data: {
            hero_image: {
              url: string;
            };
            title: {
              text: string;
            };
          };
        };
      }
    ];
  };
};

const RelatedArticles = ({ uid }: RelatedArticlesProps) => {
  const renderArticles = (data: AllBlog) => {
    // Makes sure related articles don't include blog user is on
    const articles = data.allPrismicBlog.edges
      .filter((ele) => ele.node.uid !== uid)
      .slice(0, 8);

    return articles.map((article) => (
      <BlogArticle data={article} key={article.node.uid} />
    ));
  };

  return (
    <Wrapper>
      <Title>Related articles</Title>
      <Content>
        <StaticQuery
          query={graphql`
            {
              allPrismicBlog(limit: 9) {
                edges {
                  node {
                    uid
                    tags
                    first_publication_date
                    data {
                      hero_image {
                        url
                      }
                      title {
                        text
                      }
                    }
                  }
                }
              }
            }
          `}
          render={renderArticles}
        />
      </Content>
    </Wrapper>
  );
};

export default RelatedArticles;
