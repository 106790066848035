import React from 'react';
import { Wrapper, QuoteText, Twitter, QuoteMarks } from './styles';
import TwitterSvg from '../../assets/svgs/twitter.svg';

export type BlogQuoteProps = {
  text: string;
  uid: string;
};

const BlogQuote = ({ text, uid }: BlogQuoteProps) => {
  const url = `https://wearebo.co.uk/blog/${uid}`;

  return (
    <Wrapper>
      <QuoteMarks />
      <QuoteText>{text}</QuoteText>
      <Twitter url={url} title={text}>
        <TwitterSvg />
      </Twitter>
    </Wrapper>
  );
};

export default BlogQuote;
