import styled from 'styled-components';
import {
  colors,
  spacing,
  dimensions,
  media,
  fontSizes
} from '../../styles/theme';

export const Wrapper = styled.div`
  width: 100%;
  background-color: ${colors.backgroundGrey};
  padding: 40px ${spacing.half};
  display: flex;
  flex-direction: column;
  align-items: center;
  ${media.desktop`
    padding: 80px;
  `}
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  max-width: ${dimensions.blogWrapperWidth};
  flex-wrap: wrap;
`;

export const Title = styled.h3`
  line-height: 100%;
  font-family: 'BressayBoldItalic';
  font-size: ${fontSizes.subHeading};
  margin-bottom: ${spacing.twenty};
  ${media.desktop`
    margin-bottom: 60px;
    font-size: 30px;
  `};
`;
