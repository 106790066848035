import React from 'react';
import Layout from '../../components/layout';
import { graphql } from 'gatsby';
import SEO from '../../components/seo';
import {
  Wrapper,
  Content,
  ImageWrapper,
  FullWidth,
  Title,
  CatTitle,
  Subtitle,
  HeroImage,
} from './styles';
import BlogAuthor from '../../atoms/blogAuthor';
import BlogQuote from '../../atoms/blogQuote';
import { PrismicBlog } from './model';
import LoadableImage from '../../components/loadableImage';
import RelatedArticles from '../../components/relatedArticles';

type PageContext = {
  uid: string;
};

export type BlogTemplateProps = {
  data: PrismicBlog;
  pageContext: PageContext;
};

const BlogTemplate = ({
  data: { prismicBlog },
  pageContext: { uid },
}: BlogTemplateProps) => {
  const { data, first_publication_date } = prismicBlog;
  const isBlogPost = uid !== 'windingdown'; // NB: we're butchering a blog post for the wind-down notice
  const description = data.seo_description.text || '';
  const title = data.seo_title.text || `${data.title.text} | Bó`;

  return (
    <Layout>
      <SEO title={title} description={description} />
      <Wrapper>
        <FullWidth isMargin>
          <Content>
            {isBlogPost && (
              <CatTitle>
                BÓ | <b>{prismicBlog.tags[0].toUpperCase()}</b>
              </CatTitle>
            )}
            <Title>{data.title.text}</Title>
            <HeroImage src={data.hero_image.url} alt={data.hero_image.alt} />
          </Content>
        </FullWidth>
        <FullWidth isMargin>
          <Content>
            {isBlogPost && (
              <BlogAuthor
                author={data.author.text}
                published={first_publication_date}
              />
            )}
            <Subtitle>{data.sub_heading.text}</Subtitle>
            <div dangerouslySetInnerHTML={{ __html: data.body.html }} />
          </Content>
        </FullWidth>
        <FullWidth isMargin>
          {data.image.url && (
            <ImageWrapper>
              <LoadableImage src={data.image.url} alt={data.image.alt} />
            </ImageWrapper>
          )}
          {data.quote.text && <BlogQuote text={data.quote.text} uid={uid} />}
          <Content>
            <div dangerouslySetInnerHTML={{ __html: data.body1.html }} />
            <div dangerouslySetInnerHTML={{ __html: data.body2.html }} />
          </Content>
        </FullWidth>
      </Wrapper>
      {isBlogPost && <RelatedArticles uid={uid} />}
    </Layout>
  );
};

export const blogQuery = graphql`
  query PostBySlug($uid: String!) {
    prismicBlog(uid: { eq: $uid }) {
      uid
      first_publication_date
      tags
      data {
        hero_image {
          alt
          url
        }
        title {
          text
        }
        sub_heading {
          text
        }
        author {
          text
        }
        body {
          text
          html
        }
        image {
          alt
          url
        }
        body1 {
          text
          html
        }
        quote {
          text
        }
        body2 {
          text
          html
        }
        seo_title {
          text
        }
        seo_description {
          text
        }
      }
    }
  }
`;

export default BlogTemplate;
