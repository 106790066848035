import React from 'react';
import { Wrapper, Content, Image, Title, Body } from './styles';
import { convertDateToMonthDay } from '../../services/date';
import { Article } from './model';

export type BlogArticleProps = {
  data: Article;
};

const BlogArticle = ({ data }: BlogArticleProps) => {
  return (
    <Wrapper to={`/blog/${data.node.uid}`}>
      <Image src={data.node.data.hero_image.url} />
      <Content>
        <Title>{data.node.data.title.text}</Title>
        <Body>
          <b>{data.node.tags[0]}</b> |{' '}
          {convertDateToMonthDay(data.node.first_publication_date)}
        </Body>
      </Content>
    </Wrapper>
  );
};

export default BlogArticle;
