import styled from 'styled-components';
import { spacing } from '../../styles/theme';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: ${spacing.thirty};
`;

export const Text = styled.p`
  margin-top: 0;
  margin-bottom: 0;
`;
