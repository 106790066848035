import styled from 'styled-components';
import { fontSizes, spacing, dimensions, media } from '../../styles/theme';
import QuoteSvg from '../../assets/svgs/quote-marks.svg';
import { TwitterShareButton } from 'react-share';

export const Wrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 15px;
  max-width: ${dimensions.maxBlogWidth};
  position: relative;
`;

export const QuoteText = styled.h2`
  margin-top: -30px;
  margin-bottom: 0;
  padding-left: ${spacing.base};
  padding-right: ${spacing.base};
  font-size: ${fontSizes.link};
  display: flex;
  ${media.desktop`
    padding-left: ${spacing.thirty};
    padding-right: 0;
  `}
`;

export const Twitter = styled(TwitterShareButton)`
  position: absolute;
  bottom: -10px;
  right: 0;
  &:hover {
    cursor: pointer;
  }
`;

export const QuoteMarks = styled(QuoteSvg)``;
