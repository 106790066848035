import styled from 'styled-components';
import {
  fontSizes,
  media,
  colors,
  spacing,
  dimensions,
} from '../../styles/theme';
import LoadableImage from '../../components/loadableImage';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 0;
  margin-bottom: ${spacing.ninety};
  position: relative;
  ${media.desktop`
    margin-left: 10vw;
    margin-top: ${spacing.thirty};
  `}
`;

export const FullWidth = styled.div<{ isMargin?: boolean }>`
  margin-left: 0;
  padding-top: ${spacing.thirty};
  z-index: 1;
  ${media.desktop`
    ${(props) => props.isMargin && `margin-left: 100px;`}
  `}
`;

export const Title = styled.h2`
  margin-top: ${spacing.eighteen};
  font-size: ${fontSizes.large};
  padding-left: ${spacing.base};
  padding-right: ${spacing.base};
  ${media.tablet`
    font-size: ${fontSizes.blogTitle};
    padding-left: 0;
    padding-right: 0;  
    margin-bottom: ${spacing.thirty};
  `}
`;

export const CatTitle = styled.p`
  line-height: 130%;
  margin-bottom: 0;
  font-size: ${fontSizes.small};
  padding-left: ${spacing.base};
  padding-right: ${spacing.base};
  color: ${colors.lightText};
  ${media.tablet`
    padding-left: 0;
    padding-right: 0;  
  `}
`;

export const Subtitle = styled.i`
  font-size: ${fontSizes.subHeading};
  ${media.tablet`
    font-size: ${fontSizes.link};
  `}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${dimensions.maxBlogWidth};
  padding-left: ${spacing.base};
  padding-right: ${spacing.base};
  ${media.desktop`
    padding-left: ${spacing.thirty};
    padding-right: 0;
  `}
`;

export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: ${dimensions.maxBlogWidth};
  margin-top: ${spacing.thirty};
  margin-bottom: ${spacing.thirty};
  padding-left: ${spacing.eighteen};
  padding-right: ${spacing.eighteen};
`;

export const HeroImage = styled(LoadableImage)`
  height: auto;
  width: 100%;
  max-width: 600px;
`;
